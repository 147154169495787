import type { Podcast } from '~/utils/types'

export default () => {
  const graphQL = useGraphQL()
  const { ToCategory, ToSeoObject } = useMappers()

  const mainNavigation = useMainNavigation()
  const footerNavigation = useFooterNavigation()
  const globalSetting = useGlobalSetting()
  const podcasts = usePodcasts()
  const categories = useArticleCategories()
  const seoStore = useGlobalSeo()
  const feeds = ref(
    [] as {
      feedUrl: string
      spotifyUrl: string | null
      appleUrl: string | null
      googleUrl: string | null
    }[],
  )

  return async (gqlRootNames: string[] = [], gqlChunks: string[][] = [], gqlProps: (string | undefined)[] = [], gqlMeta: boolean[] = []) => {
    if (gqlRootNames.length) {
      const { data, error } = await useAsyncData<any>(async () => await graphQL(gqlRootNames, gqlChunks, gqlProps, gqlMeta))
      if (error.value) throw error.value

      const result: any = {}

      for (const [, rootName] of gqlRootNames.entries()) {
        if (rootName === 'globalSetting') {
          const { seo, ...rest } = data.value?.[rootName]
          globalSetting.value = rest
          seoStore.value = seo && ToSeoObject(seo)
        }

        if (rootName === 'nav1:renderNavigation') {
          mainNavigation.value = data.value?.nav1?.sort((a: any, b: any) => a.order - b.order) || []
          for (const [index, item] of mainNavigation.value.entries()) {
            if (item.items?.length) {
              mainNavigation.value[index].items = item.items.sort((a: any, b: any) => a.order - b.order)
            }
          }
        }

        if (rootName === 'nav2:renderNavigation') {
          footerNavigation.value = data.value?.nav2?.sort((a: any, b: any) => a.order - b.order) || []
          for (const [index, item] of footerNavigation.value.entries()) {
            if (item.items?.length) {
              footerNavigation.value[index].items = item.items.sort((a: any, b: any) => a.order - b.order)
            }
          }
        }

        if (rootName === 'podcast') {
          podcasts.value['basic'] = data.value?.[rootName]?.podcastBasic
          feeds.value = data.value?.[rootName]?.podcastFeeds || []

          // Fetch podcasts
          if (feeds.value.length) {
            for (const feed of feeds.value) {
              const podcast = await $fetch<Podcast>(`/api/podcast/${feed.feedUrl}`)
              if (!podcast || !Object.values(podcast).length) continue
              podcasts.value[podcast.title] = {
                ...podcast,
                spotifyUrl: feed.spotifyUrl,
                appleUrl: feed.appleUrl,
                googleUrl: feed.googleUrl,
              }
            }
          }
        }

        if (rootName === 'categories') {
          categories.value = data.value?.[rootName]?.map((val: any) => ToCategory(val))
        }

        result[rootName] = data.value[rootName]
      }

      return result
    }
  }
}
