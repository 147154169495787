import { gql, request, type Variables } from 'graphql-request'

export default () => {
  const {
    public: { host, strapiBearerToken },
  } = useRuntimeConfig()

  const renameKeys = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(renameKeys)
    } else if (obj !== null && typeof obj === 'object') {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key.endsWith('_connection') ? key.replace('_connection', '') : key, renameKeys(value)]),
      )
    }
    return obj
  }

  return async function (
    rootNames: string[],
    chunks: string[][] = [],
    rootProperties: (string | undefined)[] = [],
    getMeta: boolean[] = [],
    variables: Variables | undefined = undefined,
    type: string = 'query',
  ) {
    if (!rootNames || !rootNames.length) throw createError({ message: `GraphQLRequest: rootNames not provided!` })
    if (!chunks.length) throw createError({ message: `GraphQLRequest: chunks not provided!` })

    let finalRequestString = ''

    for (const [index, rootName] of rootNames.entries()) {
      let requestString = ''
      const rootNameWithoutAlias = rootName.split(':').pop()
      const rootNameConsiderConnection = getMeta[index] ? `${rootName}_connection` : rootName

      if (!chunks[index]?.length) {
        throw createError({
          message: `GraphQLRequest: chunks not provided for rootName: ${rootNameWithoutAlias}`,
        })
      }
      for (const chunk of chunks[index]) {
        try {
          const { default: data } = await import(`../utils/graphql/${type}/${rootNameWithoutAlias}/chunks/${chunk}.ts`)
          requestString = requestString.concat('\n', data)
        } catch (e: any) {
          try {
            requestString = requestString.concat('\n', chunk)
          } catch (e: any) {
            throw createError({ message: `GraphQLRequest: ${e}` })
          }
        }
      }

      if (rootNameWithoutAlias === 'renderNavigation') {
        finalRequestString = finalRequestString.concat(
          '\n',
          `
          ${rootName}${rootProperties[index] ? `(${rootProperties[index]})` : ''} {
            ${requestString}
          }
      `,
        )
      } else {
        finalRequestString = finalRequestString.concat(
          '\n',
          `
          ${rootNameConsiderConnection}${rootProperties[index] ? `(${rootProperties[index]})` : ''} {
            ${getMeta[index] ? 'nodes {' : ''}
            documentId
            ${requestString}
            ${getMeta[index] ? '}' : ''}
          ${
            getMeta[index]
              ? `pageInfo {
              page
              pageSize
              pageCount
              total
            }`
              : ''
          }
        }
      `,
        )
      }
    }

    const query = gql`
      ${type} {
        ${finalRequestString}
      }
    `

    try {
      const data: object = await request(`${host}/graphql`, query, variables, {
        Authorization: `Bearer ${strapiBearerToken}`,
      })
      const modifiedData = renameKeys(data)
      return {
        ...modifiedData,
      }
    } catch (e: any) {
      throw createError({
        message: `GraphQLRequest: ${e.response?.error?.name || e.name} (${type}): ${e.response?.error?.message || e.message}`,
        status: e.response?.status || 500,
      })
    }
  }
}
